.App {
  background-color: #222;
  overflow-x: hidden;
  text-align: center;
}

.star {
  position: absolute;
  background-color: #fff;
}

#star-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

#navbar {
  position: fixed;
  top: 50px;
  left: 10px;
}

.navlink {
  color: #aaa;
  font-family: 'Baloo Tamma 2', cursive;
  font-size: 16px;
  text-align: left;
}

.navlink:hover {
  color: #444;
  cursor: pointer;
}

.line {
  height: 25px;
  width: 1px;
  background-color: #aaa;
}

::-webkit-scrollbar {
  width: 10px;
  background: #000;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #444;
  background: #333;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

#header {
  font-family: 'Alata', sans-serif;
  font-size: 72px;
  color: #FFF;
}

#to-top {
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-family: 'Baloo Tamma 2', cursive;
  font-size: 24px;
  color: #000;
  background-color: #aaa;
  width: 60px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  padding-top: 5px;
  filter: drop-shadow(0px 0px 24px #000);
}

#to-top:hover {
  background-color: #888;
  cursor: pointer;
}

.subheader {
  font-family: 'Baloo Tamma 2', cursive;
  font-size: 44px;
  color: #BBB;
  width: 100vw;
  height: 100px;
  text-align: center;
}

.icon-link {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #FFF;
  padding: 10px;
  margin: 15px;
  border-radius: 100%;
  vertical-align: middle;
}

.icon-link:hover {
  background-color: #ccc;
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 0px;
}

.section-header {
  font-family: 'Alata', sans-serif;
  font-size: 60px;
  margin-top: 100px;
  margin-bottom: 50px;
  color: #FFF;
}

img {
  width: 500px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px #000);
}

.proj-title {
  font-family: 'Alata', sans-serif;
  font-size: 36px;
  color: #fff;
  text-decoration: none;
}

.proj-title:hover {
  text-decoration: underline;
}

.proj-desc {
  position: relative;
  font-family: 'Baloo Tamma 2', cursive;
  font-size: 18px;
  color: #fff;
  width: 80%;
  max-width: 500px;
  margin: auto;
}

.proj-container {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proj-image {
  margin: 25px;
}

.inline-link {
  color: #fff;
  text-decoration: underline;
}

.inline-link:hover {
  color: #aaa;
}

#gallery-container {
  margin: auto;
  width: 70%;
  margin-bottom: 100px;
}

#gallery-photo {
  width: 300px;
  margin: 2px;
  filter: drop-shadow(0px 0px 24px #000);
  border-radius: 5px;
}

#footer {
  font-family: 'Alata', sans-serif;
  font-size: 14px;
  color: #353535;
}